import _ from "lodash";
import { StatCard } from "../..";
import { DASHBOARD } from "../../../consts/methods.consts";
import { SimpleGrid } from "@chakra-ui/react";
import { useGetQuery } from "../../../services/api.service";
import StudentByYearChart from "./components/StudentByYearChart";
import { useSelector } from "react-redux";


const gradients = [
	'bg-gradient-to-bl from-green-100 to-green-200',
	'bg-gradient-to-bl from-purple-100 to-purple-200',
	'bg-gradient-to-bl from-teal-100 to-teal-200',
	'bg-gradient-to-bl from-red-100 to-red-200',
	'bg-gradient-to-bl from-yellow-100 to-yellow-200',
	'bg-gradient-to-bl from-blue-100 to-blue-200',
	'bg-gradient-to-bl from-indigo-100 to-indigo-200',
	'bg-gradient-to-bl from-pink-100 to-pink-200',
	'bg-gradient-to-bl from-rose-100 to-rose-200',
	'bg-gradient-to-bl from-cyan-100 to-cyan-200',
	'bg-gradient-to-bl from-fuchsia-100 to-fuchsia-200'

]

const options = {
	legend: {},
	tooltip: {},
	dataset: {
		dimensions: ['product', 'Visitors', 'Students', 'Staff'],
		source: [
			{ product: '1', Visitors: 543, Students: 312, Staff: 218 },
			{ product: '2', Visitors: 632, Students: 275, Staff: 197 },
			{ product: '3', Visitors: 467, Students: 189, Staff: 173 },
			{ product: '4', Visitors: 723, Students: 398, Staff: 282 },
			{ product: '5', Visitors: 821, Students: 457, Staff: 313 },
			{ product: '6', Visitors: 654, Students: 287, Staff: 211 },
			{ product: '7', Visitors: 389, Students: 156, Staff: 122 },
			{ product: '8', Visitors: 432, Students: 187, Staff: 134 },
			{ product: '9', Visitors: 278, Students: 94, Staff: 72 },
			{ product: '10', Visitors: 297, Students: 105, Staff: 81 },
			{ product: '11', Visitors: 311, Students: 108, Staff: 83 },
			{ product: '12', Visitors: 286, Students: 99, Staff: 76 },
			{ product: '13', Visitors: 265, Students: 91, Staff: 68 },
			{ product: '14', Visitors: 398, Students: 142, Staff: 110 },
			{ product: '15', Visitors: 412, Students: 149, Staff: 112 },
			{ product: '16', Visitors: 423, Students: 154, Staff: 119 },
			{ product: '17', Visitors: 365, Students: 126, Staff: 97 },
			{ product: '18', Visitors: 289, Students: 98, Staff: 75 },
			{ product: '19', Visitors: 305, Students: 105, Staff: 80 },
			{ product: '20', Visitors: 278, Students: 92, Staff: 70 },
			{ product: '21', Visitors: 311, Students: 109, Staff: 83 },
			{ product: '22', Visitors: 332, Students: 116, Staff: 89 },
		]
	},
	grid: {
		top: 50,
		bottom: 30,
		left: '5%',
		right: '5%',
	},
	xAxis: { type: 'category' },
	yAxis: {},
	series: [{ type: 'bar', itemStyle: { color: '#34d399' } }, { type: 'bar', itemStyle: { color: '#a78bfa' } }, { type: 'bar', itemStyle: { color: '#22d3ee' } }]
}

const HomePage = () => {
	const user = useSelector((state: any) => _.get(state, 'common.user'))

	const { data, isLoading } = useGetQuery({
		method: DASHBOARD.GET_STAT,
		providesTags: []
	}, { refetchOnMountOrArgChange: true })

	const stats: any = _.get(data, 'message', [])


	if (_.get(user, 'roles', []).some((role:any )=> ["Admin", "DG","MC", "ADG", "Program Officer", "Consultant"].includes(role))) {
		return (
			<div className="">
				<SimpleGrid columns={{ base: 1, md: 4 }} gap={{ base: '4', md: '4' }}>
					{stats.map((stat: any, id: any) => (
						<StatCard key={id} {...stat} />
					))}
				</SimpleGrid>

				{/* <div className=" mt-10">
					<StudentByYearChart />
				</div> */}

			</div>

		)
	}

	return (
		<div>


		</div>
	)


}

export default HomePage;
