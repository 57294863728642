import { SimpleGrid, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { RESERVED_CLASSROOM } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, RESERVED_CLASSROOM_TAG } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import { FormInput } from "../../common/form-controllers/FormInput";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";
import { useObjChange } from "../../hooks";
import ClassRoomSelector from "./components/ClassRoomSelector";

const ViewReservedClassRoomPage = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create, { isLoading: creating }] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: RESERVED_CLASSROOM.GET,
        body: { id },
        providesTags: [RESERVED_CLASSROOM_TAG.VIEW]
    })

    const data = _.get(_data, ['message'], {})

    const stringifyData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            to: Yup.string().required('To date is required'),
            from: Yup.string().required('From date is required'),
            reserved_by: Yup.string().required('Reserved by is required'),
            class_room: Yup.string().required('Class Room is required'),
            contact_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number'),
            email: Yup.string().email('Enter valid email address').nullable(),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: RESERVED_CLASSROOM.UPDATE,
                    body: values,
                    invalidatesTags: [RESERVED_CLASSROOM_TAG.VIEW, RESERVED_CLASSROOM_TAG.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Class room reservation details have been saved").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const deleteHandler = async () => {
        try {
            await create({
                method: RESERVED_CLASSROOM.DELETE,
                body: { id },
                invalidatesTags: [RESERVED_CLASSROOM_TAG.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Class room reservation is deleted successfully.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/class-room-reservation`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            // toast.setTitle("Error").setDescription('Employee deletion failed. Please try again').showErrorToast();
        }
    }

    const { isChanged, setInitialObj } = useObjChange(formik.values)


    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues({
                ...data,
                to: moment(_.get(data, 'to')).format("YYYY-MM-DDTHH:mm:ss"),
                from: moment(_.get(data, 'from')).format("YYYY-MM-DDTHH:mm:ss")
            })
            setInitialObj({
                ...data,
                to: moment(_.get(data, 'to')).format("YYYY-MM-DDTHH:mm:ss"),
                from: moment(_.get(data, 'from')).format("YYYY-MM-DDTHH:mm:ss")
            })
        }
    }, [stringifyData])

    const to_date = _.get(formik.values, 'to')
    const from_date = _.get(formik.values, 'from')

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Class Room Reservation'} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader enableBackButton isLoading={creating} actions={actions} title={_.get(data, 'class_room', '')}
                subtitle={'Class Room Reservation'} />
            <SectionCard backgroundColor="bg-white" title="Duration Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired type={'datetime-local'} label="From" formik={formik} name="from" />
                    <FormInput isRequired type={'datetime-local'} label="To" formik={formik} name="to" />
                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <ClassRoomSelector formik={formik} body={{ from: from_date, to: to_date, doc: id }} name="class_room" />
                    <FormInput isRequired label="Reserved By (Name)" formik={formik} name="reserved_by" />
                    <FormInput isRequired label="Contact Number" formik={formik} name="contact_number" />
                    <FormInput label="Email" formik={formik} name="email" />
                </SimpleGrid>
            </SectionCard>
        </div>
    )
}

export default ViewReservedClassRoomPage


