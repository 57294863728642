const reportTableRows = [
  // {
  //     report_name: "Employee report",
  //     report: 'employee',

  // },
  {
    report_name: "Session Details Report",
    report: "session",
  },
  {
    report_name: "Program report",
    report: "program",
  },
  {
    report_name: "Daily Program Schedule",
    report: "daily-program",
  },
  {
    report_name: "Attendance report",
    report: "custom/attendance-report",
  },
  {
    report_name: "Attendance report",
    report: "attendance-report",
  },
  {
    report_name: "Classroom Allocation Report",
    report: "classroom",
  },
  {
    report_name: "Resource Person Allocation Report",
    report: "resource-person",
  },
  // {
  //   report_name: "Module Plan Amendment Report",
  //   report: "module-plan-amendment",
  // },
];

export default reportTableRows;
