import { Alert, AlertIcon, SimpleGrid, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { NavigateFunction, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { RESERVED_CLASSROOM } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, RESERVED_CLASSROOM_TAG } from "../../../consts/tags.consts";
import { usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import { FormInput } from "../../common/form-controllers/FormInput";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";
import ClassRoomSelector from "./components/ClassRoomSelector";

const NewReservedClassRoomPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create, { isLoading }] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            to: Yup.string().required('To date is required'),
            from: Yup.string().required('From date is required'),
            reserved_by: Yup.string().required('Reserved by is required'),
            class_room: Yup.string().required('Class Room is required'),
            contact_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number').required('Contact Number by is required'),
            email: Yup.string().email('Enter valid email address').nullable(),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: RESERVED_CLASSROOM.ADD,
                    body: values,
                    invalidatesTags: [RESERVED_CLASSROOM_TAG.VIEW, RESERVED_CLASSROOM_TAG.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Classroom Reservation failed.")
                }

                toast.setTitle("Success").setDescription("Class room reservation has been successfully created.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/class-room-reservation/${newId}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled:isLoading,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const to_date = _.get(formik.values, 'to')
    const from_date = _.get(formik.values, 'from')

    return (
        <div>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={"Create New Class Room Reservation"}
                subtitle={'Class Room Reservation'} />
            <Alert status='warning' rounded={'md'}>
                <AlertIcon />
                Please ensure that you've chosen a from date and to date before selecting a classroom.
            </Alert>
            <SectionCard backgroundColor="bg-white" title="Duration Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired type={'datetime-local'} label="From" formik={formik} name="from" />
                    <FormInput isRequired type={'datetime-local'} label="To" formik={formik} name="to" />
                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <ClassRoomSelector formik={formik} body={{ from: from_date, to: to_date }} name="class_room" />
                    <FormInput isRequired label="Reserved By (Name)" formik={formik} name="reserved_by" />
                    <FormInput isRequired label="Contact Number" formik={formik} name="contact_number" />
                    <FormInput label="Email" formik={formik} name="email" />
                </SimpleGrid>
            </SectionCard>
        </div>
    )

}

export default NewReservedClassRoomPage