import { Alert, AlertIcon, SimpleGrid, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { SESSION } from "../../../consts/methods.consts";
import { CALENDAR_TAGS, DROPDOWN_TAGS, SESSION_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";

const NewSessionPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [searchParams] = useSearchParams()
    const [create, { isLoading }] = usePostMutation();

    const formik = useFormik({
        initialValues: { "session_type": "Individual" },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            session_type: Yup.string().required("Session type is required"),
            programmed_module: Yup.string().required("Programmed module is required"),
            program: Yup.string().required("Program is required"),
            session_day: Yup.number().required("Session day is required"),
            // session_code: Yup.string().required("Session Code is required"),
            session_number: Yup.string().required("Session number is required"),
            class_room: Yup.string().required("Class room is required"),
            start_date_andtime: Yup.date()
                .required("Start Date and time is required")
                .test(
                    "from-date-validation",
                    "Start date and time must be before end date and time",
                    (value, context) => {
                        const { end_date_and_time } = context.parent;
                        return end_date_and_time ? value <= end_date_and_time : true;
                    }
                ),
            end_date_and_time: Yup.date()
                .required("End Date and Time is required")
                .test(
                    "to-date-validation",
                    "End date and time must be after start date and time",
                    (value, context) => {
                        const { start_date_andtime } = context.parent;
                        return start_date_andtime ? value >= start_date_andtime : true;
                    }
                ),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: SESSION.ADD,
                    body: values,
                    invalidatesTags: [SESSION_TAGS.VIEW, SESSION_TAGS.LIST, DROPDOWN_TAGS.LIST, CALENDAR_TAGS.EVENTS]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Session creation failed")
                }

                toast.setTitle("Success").setDescription("Session has been created successfully.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/program-module/${searchParams.get('pm')}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const { data: initialData } = useGetQuery({
        method: SESSION.INIT,
        body: {
            program: _.get(formik.values, 'program', ''),
            startDateAndTime: _.get(formik.values, 'start_date_andtime'),
            endDateAndTime: _.get(formik.values, 'end_date_and_time'),
        },
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    useEffect(() => {
        formik.setFieldValue('programmed_module', searchParams.get('pm'))
        formik.setFieldValue('program', searchParams.get('p'))
    }, [searchParams])

    useEffect(() => {
        formik.setFieldValue('resource_person', '')
        formik.setFieldValue('class_room', '')
    }, [JSON.stringify(_.get(formik.values, 'session_type'))])

    useEffect(() => {
        formik.setFieldValue('resource_person', '')
        formik.setFieldValue('class_room', '')
    }, [JSON.stringify(_.get(formik.values, 'start_date_andtime')), JSON.stringify(_.get(formik.values, 'end_date_and_time'))])

    const dropDownData = _.get(initialData, 'message')

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Create New Session'} subtitle={'Session'} />
            <Alert status='warning' rounded={'md'}>
                <AlertIcon />
                Please ensure that you've chosen a start date and time before selecting a resource person and a classroom.
            </Alert>
            <SectionCard backgroundColor=" bg-white" title="Dates & Times" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired type={'datetime-local'} label="Start Date and Time" formik={formik}
                        name="start_date_andtime" />

                    <FormInput isRequired type={'datetime-local'} label="End Date and Time" formik={formik}
                        name="end_date_and_time" />

                    <FormInput type={'number'} label="Session Duration(Hours)" formik={formik} name="session_durationhours" />

                    <FormInput type="session_day" isRequired label="Session Day" formik={formik} name="session_day" />

                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    {/* <FormInput isRequired label="Code" formik={formik} name="session_code" /> */}

                    <FormInput isRequired label="Session Title" formik={formik} name="title" />

                    <FormInput type={'number'} isRequired label="Session Number" formik={formik}
                        name="session_number" />

                    <FormSelect isRequired formik={formik} name='session_type' label="Session Type" options={[
                        {
                            "label": "Combine",
                            "value": "Combine"
                        },
                        {
                            "label": "Individual",
                            "value": "Individual"
                        }
                    ]} />

                    <FormSelect isDisabled={!(_.get(formik.values, 'start_date_andtime') && _.get(formik.values, 'end_date_and_time'))}
                        isMulti
                        options={_.get(formik.values, 'session_type') == 'Combine' ? _.get(dropDownData, 'resource_persons_combine_session', []) : _.get(dropDownData, 'resource_persons', [])}
                        label="Resource person" formik={formik}
                        name="resource_person" />
                    <FormSelect isRequired
                        isDisabled={!(_.get(formik.values, 'start_date_andtime') && _.get(formik.values, 'end_date_and_time'))}
                        options={_.get(formik.values, 'session_type') == 'Combine' ? _.get(dropDownData, 'class_room_for_combine_session', []) : _.get(dropDownData, 'class_rooms', [])}
                        label="Class Room" formik={formik}
                        name="class_room" />

                </SimpleGrid>
            </SectionCard>

            <SectionCard backgroundColor=" bg-white" title="Program Related Inforamtion" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormDropdown options={_.get(dropDownData, 'programs')} isRequired formik={formik}
                        isDisabled
                        name={'program'}
                        autoFocus
                        label={'Program'} />

                    <FormDropdown options={_.get(dropDownData, 'programmed_modules')} isRequired formik={formik}
                        isDisabled
                        name={'programmed_module'}
                        autoFocus
                        label={'Program Module'} />
                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Session Content" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 2 }} spacing={3}>
                    <FormTextArea label="" formik={formik} name="description" />
                </SimpleGrid>
            </SectionCard>
        </div>
    )
}

export default NewSessionPage