import _ from "lodash";
import { useSelector } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import { ExternalLayout, InternalLayout } from "../components/layouts";
import {
    AttendanceListPage,
    CalendarPage,
    ClassRoomListPage,
    CreateProgrammedModulePage,
    EmployeeListPage,
    ExamListPage,
    ExamRegistrationsListPage,
    HomePage,
    ImportDataListPage,
    ListModuleEnrollmentRequestPage,
    LoginPage,
    NewEmployeePage,
    NewExamPage,
    NewExamRegistrationPage,
    NewImportDataPage,
    NewOrganizationPage,
    NewResourcePersonPage,
    NewStudentPage,
    NewSubjectPage,
    OrganizationListPage,
    ProgramCategoryListPage, ProgramSubtypeListPage, ProgramTypeListPage,
    ProgrammedModuleListPage,
    ResourcePersonListPage,
    ServiceListPage,
    StudentListPage,
    SubjectListPage,
    UserNewExamRegisterPage,
    ViewEmployeePage,
    ViewExamEnrollmentPage,
    ViewExamPage,
    ViewImportDataPage,
    ViewOrganizationPage,
    ViewProgrammedModule, ViewResourcePersonPage, ViewStudentPage, ViewSubjectPage, ProgramModuleApprovalListPage, ReservedClassroomListPage, NewReservedClassRoomPage, ViewReservedClassRoomPage
} from "../components/pages";
import ModuleBucketTypeListPage from "../components/pages/module-bucket-type/ModuleBucketTypeListPage";
import ModuleBucketListPage from "../components/pages/module-bucket/ModuleBucketListPage";
import NewModuleBucketPage from "../components/pages/module-bucket/NewModuleBucketPage";
import ViewModuleBucketPage from "../components/pages/module-bucket/view-module-bucket-page/ViewModuleBucketPage";
import ModuleListPage from "../components/pages/module/ModuleListPage";
import NewModulePage from "../components/pages/module/NewModulePage";
import ViewModulePage from "../components/pages/module/ViewModulePage";
import NewProgramPage from "../components/pages/program/NewProgramPage";
import ProgramListPage from "../components/pages/program/ProgramListPage";
import ViewProgramPage from "../components/pages/program/ViewProgramPage";
import ReportHomePage from "../components/pages/report/ReportHomePage";
import ReportPage from "../components/pages/report/ReportPage";
import NewSessionPage from "../components/pages/session/NewSessionPage";
import ViewSessionPage from "../components/pages/session/ViewSessionPage";
import PageNotFound from "../components/pages/page-not-found/PageNotFound";
import PermissionDenied from "../components/pages/permission-denied/PermissionDenied";
import RestPasswordPage from "../components/pages/reset-password/RestPasswordPage";
import AmendmentRequestListPage from "../components/pages/amendment-request/AmendmentRequestListPage";
import ViewAmendmentPage from "../components/pages/amendment-request/ViewAmendmentRequestPage";
import AttendanceReportPage from "../components/pages/report/custom-report-pages/AttendanceReportPage";
import ProgramModuleWFHistoryPage from "../components/pages/program-module-workflow-history/ProgramModuleWFHistoryPage";
import AmendmentRequestHistoryPage from "../components/pages/amendment-request-history/AmendmentRequestHistoryPage";
import ModuleEnrollmentUploadLogListPage from "../components/pages/module-enrollment-upload-logs/EnrollmentUploadLogListPage";
import ViewEnrolmentUploadLogs from "../components/pages/module-enrollment-upload-logs/ViewEnrolmentUploadLogs";

const RouterConfigs = () => {
    const user = useSelector((state: any) => _.get(state, 'common.user'))

    const element = useRoutes([
        {
            path: "/",
            element: <ExternalLayout />,
            children: [
                {
                    index: true,
                    element: <LoginPage />
                },
                {
                    path: "/change-password/:key",
                    element: <RestPasswordPage />
                },
            ]
        },
        {
            path: "/application",
            children: [
                {
                    index: true,
                    element: <UserNewExamRegisterPage />
                },
            ]
        },
        {
            path: "/app",
            element: <InternalLayout />,
            children: [
                { index: true, element: <HomePage /> },
                {
                    path: 'modules', element:
                        <RouteGuard user={user} path={'modules'} >
                            <ModuleListPage />
                        </RouteGuard>
                },
                {
                    path: 'modules/new', element:
                        <RouteGuard user={user} path={'modules/new'} >
                            <NewModulePage />
                        </RouteGuard>
                },
                {
                    path: 'modules/:id', element:
                        <RouteGuard user={user} path={'modules/:id'} >
                            <ViewModulePage />
                        </RouteGuard>
                },
                {
                    path: 'module-basket', element:
                        <RouteGuard user={user} path={'module-basket'} >
                            <ModuleBucketListPage />
                        </RouteGuard>
                },
                {
                    path: 'module-basket-type', element:
                        <RouteGuard user={user} path={'module-basket-type'} >
                            <ModuleBucketTypeListPage />
                        </RouteGuard>
                },
                {
                    path: 'module-basket/new', element:
                        <RouteGuard user={user} path={'module-basket/new'} >
                            <NewModuleBucketPage />
                        </RouteGuard>
                },
                {
                    path: 'module-basket/:id', element:
                        <RouteGuard user={user} path={'module-basket/:id'} >
                            <ViewModuleBucketPage />
                        </RouteGuard>
                },

                {
                    path: 'program', element:
                        <RouteGuard user={user} path={'program'} >
                            <ProgramListPage />
                        </RouteGuard>
                },
                {
                    path: 'program/:id', element:
                        <RouteGuard user={user} path={'program/:id'} >
                            <ViewProgramPage />
                        </RouteGuard>
                },
                {
                    path: 'program/new', element:
                        <RouteGuard user={user} path={'program/new'} >
                            <NewProgramPage />
                        </RouteGuard>
                },

                {
                    path: 'session/:id', element:
                        <RouteGuard user={user} path={'session/:id'} >
                            <ViewSessionPage />
                        </RouteGuard>
                },
                {
                    path: 'session/new', element:
                        <RouteGuard user={user} path={'session/new'} >
                            <NewSessionPage />
                        </RouteGuard>
                },
                {
                    path: 'program-category', element:
                        <RouteGuard user={user} path={'program-category'} >
                            <ProgramCategoryListPage />
                        </RouteGuard>
                },

                {
                    path: 'program-type', element:
                        <RouteGuard user={user} path={'program-type'} >
                            <ProgramTypeListPage />
                        </RouteGuard>
                },

                {
                    path: 'program-subtype', element:
                        <RouteGuard user={user} path={'program-subtype'} >
                            <ProgramSubtypeListPage />
                        </RouteGuard>
                },

                {
                    path: 'calendar', element:
                        <RouteGuard user={user} path={'calendar'} >
                            <CalendarPage />
                        </RouteGuard>
                },

                {
                    path: 'organization', element:
                        <RouteGuard user={user} path={'organization'} >
                            <OrganizationListPage />
                        </RouteGuard>
                },
                {
                    path: 'organization/:id', element:
                        <RouteGuard user={user} path={'organization/:id'} >
                            <ViewOrganizationPage />
                        </RouteGuard>
                },
                {
                    path: 'organization/new', element:
                        <RouteGuard user={user} path={'organization/new'} >
                            <NewOrganizationPage />
                        </RouteGuard>
                },

                {
                    path: 'resource-person', element:
                        <RouteGuard user={user} path={'resource-person'} >
                            <ResourcePersonListPage />
                        </RouteGuard>
                },
                {
                    path: 'resource-person/:id', element:
                        <RouteGuard user={user} path={'resource-person/:id'} >
                            <ViewResourcePersonPage />
                        </RouteGuard>
                },
                {
                    path: 'resource-person/new', element:
                        <RouteGuard user={user} path={'resource-person/new'} >
                            <NewResourcePersonPage />
                        </RouteGuard>
                },

                {
                    path: 'employee', element:
                        <RouteGuard user={user} path={'employee'} >
                            <EmployeeListPage />
                        </RouteGuard>
                },
                {
                    path: 'employee/new', element:
                        <RouteGuard user={user} path={'employee/new'} >
                            <NewEmployeePage />
                        </RouteGuard>
                },
                {
                    path: 'employee/:id', element:
                        <RouteGuard user={user} path={'employee/:id'} >
                            <ViewEmployeePage />
                        </RouteGuard>
                },

                {
                    path: 'program-module', element:
                        <RouteGuard user={user} path={'program-module'} >
                            <ProgrammedModuleListPage />
                        </RouteGuard>
                },
                {
                    path: 'program-module-approval', element:
                        <RouteGuard user={user} path={'program-module-approval'} >
                            <ProgramModuleApprovalListPage />
                        </RouteGuard>
                },
                {
                    path: 'program-module/:id', element:
                        <RouteGuard user={user} path={'program-module/:id'} >
                            <ViewProgrammedModule />
                        </RouteGuard>
                },
                {
                    path: 'program-module/new', element:
                        <RouteGuard user={user} path={'program-module/new'} >
                            <CreateProgrammedModulePage />
                        </RouteGuard>
                },

                {
                    path: 'student', element:
                        <RouteGuard user={user} path={'student'} >
                            <StudentListPage />
                        </RouteGuard>
                },
                {
                    path: 'student/:id', element:
                        <RouteGuard user={user} path={'student/:id'} >
                            <ViewStudentPage />
                        </RouteGuard>
                },
                {
                    path: 'student/new', element:
                        <RouteGuard user={user} path={'student/new'} >
                            <NewStudentPage />
                        </RouteGuard>
                },

                {
                    path: 'attendance', element:
                        <RouteGuard user={user} path={'attendance'} >
                            <AttendanceListPage />
                        </RouteGuard>
                },
                {
                    path: 'class-room', element:
                        <RouteGuard user={user} path={'class-room'} >
                            <ClassRoomListPage />
                        </RouteGuard>
                },
                {
                    path: 'report-page', element:
                        <RouteGuard user={user} path={'report-page'} >
                            <ReportHomePage />
                        </RouteGuard>
                },
                {
                    path: 'report',
                    children: [
                        {
                            path: ':id',
                            element:
                                <RouteGuard user={user} path={'report/:id'} >
                                    <ReportPage />
                                </RouteGuard>
                        },
                        {
                            path: 'custom',
                            children: [
                                {
                                    path: 'attendance-report',
                                    element:
                                        <RouteGuard user={user} path={'report/:id'} >
                                            <AttendanceReportPage />
                                        </RouteGuard>
                                }
                            ]
                        },

                    ]
                },

                {
                    path: 'exam', element:
                        <RouteGuard user={user} path={'exam'} >
                            <ExamListPage />
                        </RouteGuard>
                },
                {
                    path: 'exam/:id', element:
                        <RouteGuard user={user} path={'exam/:id'} >
                            <ViewExamPage />
                        </RouteGuard>
                },
                {
                    path: 'exam/new', element:
                        <RouteGuard user={user} path={'exam/new'} >
                            <NewExamPage />
                        </RouteGuard>
                },

                {
                    path: 'subject', element:
                        <RouteGuard user={user} path={'subject'} >
                            <SubjectListPage />
                        </RouteGuard>

                },
                {
                    path: 'subject/:id', element:
                        <RouteGuard user={user} path={'subject/:id'} >
                            <ViewSubjectPage />
                        </RouteGuard>
                },
                {
                    path: 'subject/new', element:
                        <RouteGuard user={user} path={'subject/new'} >
                            <NewSubjectPage />
                        </RouteGuard>
                },

                {
                    path: 'service', element:
                        <RouteGuard user={user} path={'service'} >
                            <ServiceListPage />
                        </RouteGuard>
                },

                {
                    path: 'exam-registration', element:
                        <RouteGuard user={user} path={'exam-registration'} >
                            <ExamRegistrationsListPage />
                        </RouteGuard>
                },
                {
                    path: 'exam-registration/new', element:
                        <RouteGuard user={user} path={'exam-registration/new'} >
                            <NewExamRegistrationPage />
                        </RouteGuard>
                },
                {
                    path: 'exam-registration/view', element:
                        <RouteGuard user={user} path={'exam-registration/view'} >
                            <ViewExamEnrollmentPage />
                        </RouteGuard>
                },

                {
                    path: 'data-import', element:
                        <RouteGuard user={user} path={'data-import'} >
                            <ImportDataListPage />
                        </RouteGuard>
                },
                {
                    path: 'data-import/:id', element:
                        <RouteGuard user={user} path={'data-import/:id'} >
                            <ViewImportDataPage />
                        </RouteGuard>
                },
                {
                    path: 'data-import/new', element:
                        <RouteGuard user={user} path={'data-import/new'} >
                            <NewImportDataPage />
                        </RouteGuard >
                },
                {
                    path: 'module-enrollment-request', element:
                        <RouteGuard user={user} path={'data-import/new'} >
                            <ListModuleEnrollmentRequestPage />
                        </RouteGuard >
                },
                {
                    path: 'class-room-reservation', element:
                        <RouteGuard user={user} path={'class-room-reservation'} >
                            <ReservedClassroomListPage />
                        </RouteGuard >
                },
                {
                    path: 'class-room-reservation/new', element:
                        <RouteGuard user={user} path={'class-room-reservation/new'} >
                            <NewReservedClassRoomPage />
                        </RouteGuard >
                },
                {
                    path: 'class-room-reservation/:id', element:
                        <RouteGuard user={user} path={'class-room-reservation/:id'} >
                            <ViewReservedClassRoomPage />
                        </RouteGuard >
                },
                {
                    path: 'amendment-request', element:
                        <RouteGuard user={user} path={'amendment-request'} >
                            <AmendmentRequestListPage />
                        </RouteGuard >
                },
                {
                    path: 'amendment-request/:id', element:
                        <RouteGuard user={user} path={'amendment-request/:id'} >
                            <ViewAmendmentPage />
                        </RouteGuard >
                },
                {
                    path: 'program-module-workflow-history', element:
                        <RouteGuard user={user} path={'program-module-workflow-history'} >
                            <ProgramModuleWFHistoryPage />
                        </RouteGuard >
                },
                {
                    path: 'amendment-history', element:
                        <RouteGuard user={user} path={'amendment-history'} >
                            <AmendmentRequestHistoryPage />
                        </RouteGuard >
                },
                {
                    path: 'module-enrolment-excel-logs', element:
                        <RouteGuard user={user} path={'module-enrolment-excel-logs'} >
                            <ModuleEnrollmentUploadLogListPage />
                        </RouteGuard >
                },
                {
                    path: 'module-enrolment-excel-logs/:id', element:
                        <RouteGuard user={user} path={'module-enrolment-excel-logs/:id'} >
                            <ViewEnrolmentUploadLogs />
                        </RouteGuard >
                },
            ],
        },
        { path: "*", element: <PageNotFound /> },
    ]);

    return element;
};

export default RouterConfigs;


const RouteGuard = ({ user, path, children }: any) => {
    const navigate = useNavigate()
    // if (!user) navigate('/')

    const found = _.get(user, 'roles', []).some((value: any) =>
        _.get(routePermissions, path, []).includes(value)
    )

    if (found) return children
    else return <PermissionDenied />

}

const routePermissions = {
    "modules": ['Admin', 'System Manager', 'Program Officer', 'Program Assistance', 'Program Assistant'],
    "modules/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "modules/:id": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'ADG', 'DG', 'Program Assistant', 'Registrar'],

    "module-basket": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "module-basket-type": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "module-basket/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "module-basket/:id": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant'],

    "program": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'ADG', 'DG', 'Program Assistant', 'Registrar'],
    "program/:id": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'ADG', 'DG', 'Program Assistant', 'Registrar', 'Registrar'],
    "program/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],

    "session-workflow": ['System Manager', 'Admin', 'Consultant', 'ADG', 'DG'],
    "session/:id": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'ADG', 'DG', 'Program Assistant', 'Registrar', 'Registrar'],
    "session/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "session-dir/:id": ['System Manager', 'Consultant', 'ADG', 'DG'],

    "program-category": ['Admin', 'System Manager'],
    "program-subtype": ['Admin', 'System Manager'],
    "program-type": ['Admin', 'System Manager'],

    "calendar": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar'],

    "organization": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "organization/:id": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "organization/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],

    "resource-person": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant'],
    "resource-person/:id": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant'],
    "resource-person/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],

    "employee": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "employee/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "employee/:id": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],

    "program-module": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant'],
    "program-module-approval": ['System Manager', 'Consultant', 'ADG', 'DG', 'Registrar'],
    "program-module/:id": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'ADG', 'DG', 'Registrar', 'Program Assistant'],
    "program-module/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],

    "student": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "student/:id": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],
    "student/new": ['Admin', 'System Manager', 'Program Officer', 'Program Assistant'],

    "module-enrollment": ['Admin', 'System Manager'],
    "module-enrollment/:id": ['Admin', 'System Manager'],
    "module-enrollment/new": ['Admin', 'System Manager'],

    "attendance": ['Admin', 'System Manager'],
    "class-room": ['Admin', 'System Manager'],
    "report-page": ['Admin', 'Exam Division', 'Program Officer', 'System Manager', 'Consultant', 'Program Assistant', 'Registrar','ADG','DG'],
    "report/:id": ['Admin', 'Exam Division', 'Program Officer', 'System Manager', 'Consultant', 'Program Assistant', 'Registrar','ADG','DG'],
    "attendance-report": ['Admin', 'Exam Division', 'Program Officer', 'System Manager', 'Consultant', 'Program Assistant', 'Registrar','ADG','DG'],

    "exam": ['Admin', 'Exam Division', 'System Manager'],
    "exam/:id": ['Admin', 'Exam Division', 'System Manager'],
    "exam/new": ['Admin', 'Exam Division', 'System Manager'],

    "subject": ['Admin', 'Exam Division', 'System Manager'],
    "subject/:id": ['Admin', 'Exam Division', 'System Manager'],
    "subject/new": ['Admin', 'Exam Division', 'System Manager'],

    "service": ['Admin', 'Exam Division', 'System Manager'],

    "exam-registration": ['Admin', 'Exam Division', 'System Manager', 'directorSLAS', 'directorSLES', 'directorSLSS', 'directorSLAcS', 'directorSLPS', 'directorSLVS'],
    "exam-registration/new": ['Admin', 'Exam Division', 'System Manager'],
    "exam-registration/view": ['Admin', 'Exam Division', 'System Manager', 'directorSLAS', 'directorSLES', 'directorSLSS', 'directorSLAcS', 'directorSLPS', 'directorSLVS'],

    "data-import": ['Admin', 'System Manager'],
    "data-import/:id": ['Admin', 'System Manager'],
    "data-import/new": ['Admin', 'System Manager'],

    "module-enrollment-request": ['Admin', 'System Manager'],

    "class-room-reservation": ['Admin', 'Program Officer', 'System Manager', 'Administrator', 'Program Assistant', 'Registrar'],
    "class-room-reservation/:id": ['Admin', 'Program Officer', 'System Manager', 'Program Assistant', 'Registrar'],
    "class-room-reservation/new": ['Admin', 'Program Officer', 'System Manager', 'Program Assistant'],

    "amendment-request": ['Admin', 'ADG', 'System Manager','Registrar','Consultant'],
    "amendment-request/:id": ['Admin', 'ADG', 'DG', 'System Manager','Registrar','Consultant',"Program Officer", "Program Assistant"],
    "program-module-workflow-history": ['Consultant', 'ADG', 'DG', 'Registrar'],
    "amendment-history": ['ADG', 'DG','Registrar','Consultant',"Program Officer", "Program Assistant"],
    "module-enrolment-excel-logs": ["Program Officer", "Program Assistant","System Manager"],
    "module-enrolment-excel-logs/:id": ["Program Officer", "Program Assistant","System Manager"]
}