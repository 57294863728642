import { Alert, AlertIcon, Button, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Stack, useToast } from "@chakra-ui/react"
import { useFormik } from "formik"
import _ from "lodash"
import { useState } from "react"
import { IoIosArrowDown } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"
import { ChakraTable, InnerLoading, SectionCard } from "../.."
import { REPORT_TAGS } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"
import { ToastService } from "../../../services/toast.service"
import { showConfirmDialog } from "../../../store/reducers/confirm-modal"
import { FormDropdown } from "../../common/form-controllers/FormDropdown"
import { FormInput } from "../../common/form-controllers/FormInput"
import FormSelect from "../../common/form-controllers/FormSelect"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import { useTableDataProvider } from "../../hooks"
import PageNotFound from "../page-not-found/PageNotFound"
import PermissionDenied from "../permission-denied/PermissionDenied"
import config from "./config"
import reportPermission from "./ReportPermission"
import reportTableRows from "./ReportTableRows"

const ReportPage = () => {
    const { id }: any = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(0)
    const dispatch = useDispatch()
    const [filter, setFilter]: any = useState({})
    const [loading, setLoading]: any = useState(false)
    const toast = new ToastService(useToast());
    let _config: any = _.get(config, id, '')
    const roles: any = useSelector((state: any) => _.get(state, 'common.user.roles', []))

    const hasReport = reportTableRows.some(row => row.report === id);
    const hasPermission = roles.some((role: any) => reportPermission[id].includes(role));

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
            setFilter(values)
        }
    })

    const { data, isLoading, isFetching, isError } = useGetQuery({
        method: _.get(_config, 'searchApi'),
        body: {
            page: page,
            length: Number(length),
            filter: filter
        },
        providesTags: [REPORT_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);


    const filterSectionsMarkup = _.get(_config, "sections", []).map((item: any, index: any) => {
        return (
            <SectionCard key={index} backgroundColor=" bg-white" title={_.get(item, 'selectionTitle', '')} p={3} mb={4}>
                <SimpleGrid columns={_.get(item, 'grid', { base: 1, md: 2, lg: 4, xl: 4 })} spacing={3}>
                    {_.get(item, 'filters', [])?.map(({ key, is_required = false, label, type = 'text', options = [], optionMethod = '', methodBody = {}, isMulti = false }: any) => {
                        if (type === 'drop-down') {
                            return (
                                <FormDropdown options={options} formik={formik}
                                    isRequired={is_required}
                                    name={key}
                                    autoFocus
                                    label={label} />
                            )
                        }

                        if (type === 'form-select') {
                            let _methodbody: any = Object.entries(methodBody).reduce((result: any, [key, value]: any) => {
                                result[key] = _.get(formik.values, value, '');
                                return result;
                            }, {});

                            return (
                                <FormSelect isMulti={isMulti} method={optionMethod} formik={formik} body={_methodbody}
                                    isRequired={is_required}
                                    name={key}
                                    label={label} />
                            )
                        }

                        return (
                            <FormInput
                                isRequired={is_required}
                                type={type} label={label} formik={formik} name={key} />
                        )
                    }

                    )}
                </SimpleGrid>
            </SectionCard>
        )
    })

    const extraActions = _.get(config, [id, 'actions'], []).map((item: any) => {
        return {
            ...item,
            onClick: () => item.onClick(formik.values, dataList, setLoading).catch((error: any) => {
                toast.setTitle(error.title).setDescription(error.message).setStatus(error.status).show();
            })

        }
    })

    const onClickHandler = (confirm: Boolean | undefined, action: Function) => {
        if (confirm || confirm === undefined || confirm === null) {
            dispatch(
                showConfirmDialog({
                    title: "Confirmation Required!",
                    subtitle: "Are you sure you want to continue the action?",
                    onConfirm: action || (() => { })
                }))

        } else {
            action()
        }

    }

    const onLengthChange = (length: any) => {
        setPage(0)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    const actionRenderer = _.get(_config, 'extraActionsAsMenu') ? (
        <Stack direction="row" spacing="3">
            <Button key={1} colorScheme={"teal"} size={"sm"}
                variant={"solid"} onClick={() => { onClickHandler(true, formik.submitForm) }}>
                Execute Report
            </Button>
            <Button key={1} colorScheme={"red"} size={"sm"}
                variant={"solid"} onClick={
                    () => onClickHandler(true, () => {
                        formik.resetForm()
                        setFilter({})
                    })
                }>
                Reset
            </Button>
            <Menu>
                <MenuButton size={"sm"} colorScheme="teal" variant={"solid"} rightIcon={<IoIosArrowDown />} as={Button} >
                    More
                </MenuButton>
                <MenuList>
                    {
                        extraActions?.map(({ confirm, text, onClick = () => { } }: any, index: any) => {
                            return (
                                <MenuItem key={index} onClick={() => onClickHandler(confirm, onClick)}>{text}</MenuItem>
                            )
                        })
                    }
                </MenuList>
            </Menu>
        </Stack>

    ) : undefined

    const actions: PageHeaderActions = [
        ...extraActions,

        // {
        //     text: "Download as Excel",
        //     onClick: downloadExcelHandler,
        //     buttonVariant: "solid",
        //     buttonColorScheme: "teal"
        // },
        {
            text: "Execute Report",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Reset",
            onClick: () => {
                formik.resetForm()
                setFilter({})
            },
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    if (!_config || !hasReport) {
        return (
            <PageNotFound />
        )
    }

    if (!hasPermission) {
        return (
            <PermissionDenied />
        )
    }

    if (loading || isLoading) {
        return (
            <>
                <PageHeader actionRenderer={actionRenderer}
                    actions={actions} isLoading={(isLoading || loading)} subtitle="View and Manage Report" title={_.get(_config, 'title')} />
                <InnerLoading />
            </>
        )
    }

    return (
        <>
            <div>
                <PageHeader enableBackButton actionRenderer={actionRenderer}
                    actions={actions}
                    subtitle="View and Manage Report" title={_.get(_config, 'title')} />


                {_.get(_config, 'alert') && (
                    <Alert status='warning' rounded={'md'}>
                        <AlertIcon />
                        {_.get(_config, 'alert', '')}
                    </Alert>
                )}

                {filterSectionsMarkup}

                <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} isLoading={isLoading} size={"sm"}
                    paginationDetails={paginationDetails}
                    pagination
                    title="Report"
                    onSelected={() => { }} columns={_.get(config, [id, 'tableConfig', 'column'])} data={isError ? [] : (dataList || [])} />

            </div>
        </>
    )
}

export default ReportPage
